import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

import {
  locationTypeSearchParams,
  WORKSITE_NAME_SEARCH_PARAM,
  WORKSITE_LOCATION_TYPE_SEARCH_PARAM,
  locationSearchTypes,
  WORKSITE_COUNTRY_SEARCH_PARAM,
  WORKSITE_REGION_SEARCH_PARAM,
} from '../../constants/location';
import has from 'lodash/has';

const LocationSearchFilters = ({
  handleUpdateLocationSearchParams,
  allWorksites,
  allWorksiteCountries,
  allWorksiteRegions,
  initLocationSearchParams,
}) => {
  const { worksiteName = '', worksiteIds = [], worksiteLocationType = '', worksiteRegion = '', worksiteCountry = '', ...locationSearchParams } = initLocationSearchParams;

  const WorksiteLocationTypeFormControl = () => {
    return (
      <FormControl fullWidth>
        <Select
          displayEmpty
          labelId="location-type-label"
          id="location-type-select"
          value={worksiteLocationType}
          onChange={(e) => {
              handleUpdateLocationSearchParams({
                // Clear previous location type value
                [worksiteLocationType] : '',
                [WORKSITE_LOCATION_TYPE_SEARCH_PARAM]: e.target.value,
              });
            }}
        >
          {Object.keys(locationSearchTypes).map((locationType, index) => (
            <MenuItem key={index} value={locationType}>{locationSearchTypes[locationType].label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const WorksiteLocationTypeOptionsFormControl = () => {
    const options = getOptionsForLocationType();
    const defaultValue = getDefaultValueForLocationType();
    return (
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          id="location-type-options-select"
          options={options}
          defaultValue={
            defaultValue ? { label: defaultValue } : null
          }
          renderInput={(params) => (
            <TextField {...params} label="" />
          )}
          onChange={(e, value) => {
            handleUpdateLocationSearchParams({
              [worksiteLocationType]: value.label,
            });
          }}
        />
    </FormControl>
    );
  };

  const getDefaultValueForLocationType = () => {
    let value = '';
    switch (worksiteLocationType) {
      case WORKSITE_COUNTRY_SEARCH_PARAM:
        value = worksiteCountry;
      break;
      case WORKSITE_REGION_SEARCH_PARAM:
        value = worksiteRegion;
      break;
      case WORKSITE_NAME_SEARCH_PARAM:
        value = worksiteName;
      break;
      default:
        // Do nothing
    }
    return value;
  }
  const getOptionsForLocationType = () => {
    let options = [];
    switch (worksiteLocationType) {
      case WORKSITE_COUNTRY_SEARCH_PARAM:
        options = allWorksiteCountries.map((country) => ({label: country}));
      break;
      case WORKSITE_REGION_SEARCH_PARAM:
        options = allWorksiteRegions.map((region) => ({label: region}));
      break;
      case WORKSITE_NAME_SEARCH_PARAM:
        options = allWorksites.map((worksiteDetails) => {
          return { label: worksiteDetails.name };
        });
      break;
      default:
        // Do nothing
    }
    options.sort((a, b) => {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    return options;
  }

  return (
    <Grid
      container
      rowSpacing={0}
      columnSpacing={1}
      sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 2 }}
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Location</Typography>
      </Grid>
      <Grid item container columnSpacing={1} wrap="nowrap" xs={12}>
        <Grid item xs={6}>
          <WorksiteLocationTypeFormControl />
        </Grid>
        {!!worksiteLocationType && 
          <Grid item xs={6}>
            <WorksiteLocationTypeOptionsFormControl />
          </Grid>
        }
      </Grid>
      {/*
      <Grid item container wrap="nowrap" xs={12}>
        <FormControlLabel
          label="Show near me"
          control={
            <Checkbox
              value={LOCATION_TYPE_NEAR_ME}
              onChange={locationSearchParamsHandleOnChangeCheckbox}
              checked={!!locationSearchParams[LOCATION_TYPE_NEAR_ME]}
            />
          }
        />
      </Grid>
        */}
    </Grid>
  );
};
LocationSearchFilters.propTypes = {
  handleUpdateLocationSearchParams: PropTypes.func.isRequired,
  allWorksites: PropTypes.array.isRequired,
  allWorksiteCountries: PropTypes.array.isRequired,
  allWorksiteRegions: PropTypes.array.isRequired,
  initLocationSearchParams: PropTypes.object
};
LocationSearchFilters.defaultProps = {
  initLocationSearchParams: {}
};
export default LocationSearchFilters;
