export default {
    title: "Global Coral Restoration",
    toCome: "Feature to come",
    trainingHeader: "Training",
    newProjectHeader: "New Project",
    showNearMe: "Go to my location",
    cannotFindMyLocationError: 'Could not find your location',
    counterLabel: (projectNumber, orgsNumber) => `${projectNumber} projects and ${orgsNumber} organizations showing`,
    logoAttributionLabel: 'Art by: Ellen Blonder',
    logoAttributionLink: 'http://ellenblonder.com',
};