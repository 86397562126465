import React from 'react';
import { useRecoilState } from 'recoil';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { snackbarState } from './SnackbarState';

const RecoilSnackbar = () => {
    const [snackbarProps, setSnackbarProps] = useRecoilState(snackbarState);
    const { message, open, alertSeverity } = snackbarProps;
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={() => setSnackbarProps((prevState) => ({
                ...prevState,
                open: false,
                message: '',
            }))}
            autoHideDuration={2000}
        >
            <Alert severity={alertSeverity} sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
    );
}
export default RecoilSnackbar;
