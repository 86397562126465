import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailIcon from '@mui/icons-material/Email';
import NearMeIcon from '@mui/icons-material/NearMe';
import AttributionIcon from '@mui/icons-material/Attribution';
import Toolbar from '@mui/material/Toolbar';
import { Button, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '../../components/snackbar';
import messages from '../mapManager/messages';
import {getOrganizationIdsFromMethodPractices} from '../../utils/getOrganizations';
import ToolTipIconButton from '../../components/toolTipIconButton';
import ToolTipIconButtonCopyClipboard from '../../components/toolTipIconButtonCopyClipboard';
import { snackbarState } from '../../components/snackbar';
import { mapState } from '../../components/map';
import IntroDialog from '../../components/introDialog';
import SearchLocationModal from '../../components/searchLocationModal';

import isEmpty from 'lodash/isEmpty';

import Map from '../../components/map';
// import { getOrganizations } from '../../utils/getOrganizations';
import {
  getMethodologyPracticesByMethodology
  // getAllMethodologyPractice
} from '../../utils/getMethodologyPractices';
// import { getCoordinatesFromAddress } from '../../utils/geocoding';
import SearchFiltersTabs from '../../components/searchFiltersTabs';

const TRAINING_LINK = "https://reefhabilitation.org/training/";
const NEW_PROJECTS_LINK = "https://reefhabilitation.org/existing-projects/";
const EMAIL = "scalingupcoralrestoration@gmail.com";

const MapManager = ({ initMethodSearchParams, showNewProjectButton, showIntroDialog, showAppBar }) => {
  // const [getOrgsParams, setGetOrgParams] = useState({});
  const [showNewProjectLocation, setShowNewProjectLocation] = useState(false);
  const [getMethodPracticesParams, setGetMethodPracticesParams] = useState(initMethodSearchParams);
  // const [orgMarkersLoaded, setOrgMarkersLoaded] = useState(true);
  const [methodPracticeMarkersLoaded, setMethodPracticeMarkersLoaded] = useState(false);
  // const [orgMarkers, setOrgMarkers] = useState([]);
  const [methodPracticesMarkers, setMethodPracticesMarkers] = useState([]);
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const [filterIsModal, setFilterIsModal] = useState(false);
  const [introDialogOpen, setIntroDialogOpen] = useState(false);
  
  const setSnackbarProps = useSetRecoilState(snackbarState);
  const { map } = useRecoilValue(mapState);

  const panToMyLocation = () => {
    setLoadingMyLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
          };
          map.setCenter(pos);
          map.setZoom(8);
          setLoadingMyLocation(false);
        },
        () => {
        setLoadingMyLocation(false);
        setSnackbarProps((prevState) => ({
          ...prevState,
          open: true,
          message: messages.cannotFindMyLocationError,
          alertSeverity: 'error',
          }))
        }
      );
    } else {
      setLoadingMyLocation(false);
      // Browser doesn't support Geolocation
      setSnackbarProps((prevState) => ({
          ...prevState,
          open: true,
          message: messages.cannotFindMyLocationError,
          alertSeverity: 'error',
      }))      
    }
  }

  const fetchMethodPractices = async () => {
    const methodPractices = await getMethodologyPracticesByMethodology(getMethodPracticesParams);
    return methodPractices.data;
  };

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    // Return the width so we can use it in our components
    return { width };
  }

  /*
  const fetchAllMethodPractices = async () => {
    const methodPractices = await getAllMethodologyPractice();
    return methodPractices.data;
  };

  const fetchOrganizations = async () => {
    const orgs = await getOrganizations(getOrgsParams);
    const orgsWithCoords = await Promise.all(
      orgs.map(async (org) => {
        const coordinates = await getCoordinatesFromAddress({
          country: org.locationId.country,
          locality: org.locationId.municipality,
          postalCode: org.locationId.postal_code,
          address: org.locationId.address
        });
        return { ...org, coordinates };
      })
    );
    return orgsWithCoords;
  };*/

  useEffect(() => {
    if (showIntroDialog) {
      setIntroDialogOpen(true);
    }
  }, [showIntroDialog])

  useEffect(() => {
    if (!isEmpty(getMethodPracticesParams)) {
      if (!methodPracticeMarkersLoaded) {
        fetchMethodPractices().then((methodPracticeMarkers) => {
          setMethodPracticesMarkers(methodPracticeMarkers);
          setMethodPracticeMarkersLoaded(true);
        });
      }
    } else {
      setMethodPracticesMarkers([]);
    }
  }, [methodPracticeMarkersLoaded, getMethodPracticesParams]);

  // const { width } = useViewport();
  // const breakpoint = 440;
  const backgroundColor = 'rgb(104, 223, 245)';
  const allOrgIds = getOrganizationIdsFromMethodPractices(methodPracticesMarkers);
  const windowInnerWidth = window.innerWidth;
  return (
    <>
      <>
      <CssBaseline />
        <Snackbar />
        <Box sx={{ flex: 1, padding:0, alignItems: 'center', flexDirection: 'column', height: '100vh'}}>
          {showAppBar &&
          <AppBar position="static" sx = {{ backgroundColor: backgroundColor }}>
            <Toolbar disableGutters>
              <Stack spacing="0" sx={{width:'100%', marginTop:0}}>              
                  <Box sx={{display:'block', position: 'relative', lineHeight:0}}>
                    <Box sx={{position: 'relative'}}>
                      <img src={`${process.env.PUBLIC_URL}/banner.jpg`} alt="pin" style={{maxWidth:'100%'}} />
                    </Box>
                    <Box sx={{position:'absolute', bottom:0, left:2}}>
                      <ToolTipIconButton
                          buttonStyles={{padding:0}}
                          label={messages.logoAttributionLabel}
                          IconType={AttributionIcon}
                          iconProps={{fontSize:'small'}}
                          onClickCallback={() =>
                            window.open(messages.logoAttributionLink, '_blank')
                          }
                          color='black'
                        />
                    </Box>
                    <Box sx={{position:'absolute', bottom:0, right:2}}>
                          { showNewProjectButton && 
                        <Button variant="text" onClick={() => setShowNewProjectLocation(true)}>{messages.newProjectHeader}</Button>
                          }
                        <ToolTipIconButtonCopyClipboard
                          label={EMAIL}
                          IconType={EmailIcon}
                          buttonStyles={{alignItems:'flex-end'}}
                          iconProps={
                            {style:{color:'mediumblue'}}
                          }
                        />
                    </Box>
                  </Box>
                <Stack sx={{backgroundColor:"rgb(24,126,214)", paddingLeft:2, paddingRight:2}} direction="row">
                  <Box sx={{marginLeft:'auto'}}>                  
                    <Stack direction="row" spacing={2} sx={{height:"100%", alignItems:"center"}}>
                      <Box display="flex" sx={
                        {opacity: 0.9, zIndex: filterIsModal ? 'modal' : 'auto'}}
                      >
                        <SearchFiltersTabs
                          initMethodSearchParams={initMethodSearchParams}
                          onFilterOpenClose={(filtersOpen) => {
                            setFilterIsModal(filtersOpen);
                          }}
                          setSearchParams={(params) => {
                            setGetMethodPracticesParams(params);
                            setMethodPracticeMarkersLoaded(false);
                          }}
                        />
                      </Box>
                      { map &&
                      <LoadingButton
                        size="small"
                        onClick={() => panToMyLocation()}
                        endIcon={<NearMeIcon />}
                        loading={loadingMyLocation}
                        loadingPosition="end"
                        variant="contained"
                        color="info"
                        sx={{mr: 15}}
                      >
                        <Typography variant="button">{messages.showNearMe}</Typography>
                    </LoadingButton>
                      }
                      <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography color="white" variant="body1">{messages.counterLabel(methodPracticesMarkers.length, allOrgIds.length)}</Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Toolbar>
          </AppBar>
          }
          <Box sx={{height: '100%',  width: showAppBar ? '100%' : `${Math.round(windowInnerWidth/2)}px`}}>
            <Map
              // orgMarkers={orgMarkers}
              methodPracticeMarkers={methodPracticesMarkers}
              // panToMyLocation={Boolean(getMethodPracticesParams[LOCATION_TYPE_NEAR_ME])}
            />
          </Box>
          { showIntroDialog && 
          <IntroDialog open={introDialogOpen} handleClose={() => setIntroDialogOpen(false)} />
          }
        </Box>
      </>
      { showNewProjectLocation && 
      <SearchLocationModal 
        open={true} 
      />
      }
    </>
  );
};

MapManager.propTypes = {
  initMethodSearchParams: PropTypes.object,
  showNewProjectButton: PropTypes.bool,
  showIntroDialog: PropTypes.bool,
  showAppBar: PropTypes.bool,
};
MapManager.defaultProps = {
  initMethodSearchParams: {
    restorationMethodCategories: [],
    scientificMethodCategories: [],
  },
  showNewProjectButton: false,
  showIntroDialog: true,
  showAppBar: true,
};
export default MapManager;
