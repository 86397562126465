import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const DEFAULT_COLOR = 'color';
const ToolTipIconButton = ({ label, IconType, iconProps, onClickCallback, buttonStyles }) => {
  return (
    <Tooltip title={label}>
      <IconButton style={{ color:DEFAULT_COLOR, ...buttonStyles }} onClick={() => onClickCallback()}>
        {React.createElement(IconType, iconProps)}
      </IconButton>
    </Tooltip>
  );
};

ToolTipIconButton.propTypes = {
  label: PropTypes.string,
  IconType: PropTypes.elementType,
  iconProps: PropTypes.object,
  onClickCallback: PropTypes.func,
  buttonStyles: PropTypes.object,
};
ToolTipIconButton.defaultProps = {
  iconProps: {},
  onClickCallback: () => {},
  buttonStyles: {},
};

export default ToolTipIconButton;
