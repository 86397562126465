import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ToolTipIconButtonCopyClipboard from '../toolTipIconButtonCopyClipboard';

const iconButtonStyle = { color: 'blue' };

const OrgContact = ({ phone, email, facebook, twitter, instagram, linkedIn }) => {
  return (
    <>
      {phone && (<ToolTipIconButtonCopyClipboard label={phone} IconType={PhoneRoundedIcon} iconProps={{fontSize:'small'}} />)}
      {email && (<ToolTipIconButtonCopyClipboard label={email} IconType={EmailRoundedIcon} iconProps={{fontSize:'small'}} />)}
      {facebook && (
        <IconButton aria-label="facebook" size="small" style={iconButtonStyle}>
          <FacebookIcon fontSize="small" onClick={() => window.open(facebook, '_blank')} />
        </IconButton>
      )}
      {twitter && (
        <IconButton aria-label="twitter" size="small" style={iconButtonStyle}>
          <TwitterIcon fontSize="small" onClick={() => window.open(twitter, '_blank')} />
        </IconButton>
      )}
      {instagram && (
        <IconButton aria-label="instagram" size="small" style={iconButtonStyle}>
          <InstagramIcon fontSize="small" onClick={() => window.open(instagram, '_blank')} />
        </IconButton>
      )}
      {linkedIn && (
        <IconButton aria-label="linkedIn" size="small" style={iconButtonStyle}>
          <LinkedInIcon fontSize="small" onClick={() => window.open(linkedIn, '_blank')} />
        </IconButton>
      )}
    </>
  );
};

OrgContact.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  linkedIn: PropTypes.string
};
export default OrgContact;
