import { atom } from 'recoil';

export const mapState = atom({
  key: 'mapState',
  dangerouslyAllowMutability: true,
  default: {
    map: null,
    maps: null,
    loaded: false,
    initialBounds: {},
  },
});