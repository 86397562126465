import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const ToolTipIcon = ({ label, IconType, iconProps }) => {
  return <Tooltip title={label}>{React.createElement(IconType, iconProps)}</Tooltip>;
};

ToolTipIcon.propTypes = {
  label: PropTypes.string,
  IconType: PropTypes.elementType,
  iconProps: PropTypes.object
};
ToolTipIcon.defaultProps = {
  iconProps: {}
};

export default ToolTipIcon;
