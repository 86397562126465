import axios from 'axios';
import { BASE_URL_API } from '../constants';

export const getWorksiteRegions = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const sm = await axios.get(
    `${BASE_URL_API}/worksite/regions`,
    params,
    { headers }
  );
  return sm.data.map(options => options.region);
};
