import React from 'react';
import PropTypes from 'prop-types';

const OrgLogo = ({ logoPath, orgLink }) => {
  if (logoPath && logoPath.length > 0) {
    return (
      <a href={orgLink} target="_blank" rel="noreferrer">
        <img src={`${process.env.PUBLIC_URL}/logos/${logoPath}`} alt="pin" />
      </a>
    );
  }
  return '';
};

OrgLogo.propTypes = {
  logoPath: PropTypes.string.isRequired,
  orgLink: PropTypes.string
};
OrgLogo.defaultProps = {
  orgLink: '/'
};
export default OrgLogo;
