import People from '@mui/icons-material/People';
import Science from '@mui/icons-material/Science';
import Construction from '@mui/icons-material/Construction';
import EnergySavingsLeaf from '@mui/icons-material/EnergySavingsLeaf';
import ScubaDiving from '@mui/icons-material/ScubaDiving';
import CameraIndoor from '@mui/icons-material/CameraIndoor';
import Water from '@mui/icons-material/Water';
import MonitorHeart from '@mui/icons-material/MonitorHeart';

const COLUMN_NAME_BIODEGRADABLE_MATERIALS = 'uses_biodegradable_materials';
const COLUMN_NAME_COMMUNITY_BASED = 'is_community_based';
const COLUMN_NAME_IS_LABORATORY = 'is_laboratory';
const COLUMN_NAME_IS_RESTORATION = 'is_restoration';
const COLUMN_NAME_IS_EX_SITU_NURSERY = 'is_ex_situ_nursery';
const COLUMN_NAME_IS_IN_SITU_NURSERY = 'is_in_situ_nursery';
const COLUMN_NAME_IS_AQUARIUM = 'is_aquarium';
const COLUMN_NAME_IS_MONITORING = 'is_monitoring';

export const FLAG_DETAILS = {
  [COLUMN_NAME_IS_IN_SITU_NURSERY]: {
    label: 'In-Situ Nursery',
    IconType: ScubaDiving,
    iconStyle: {},
    searchParam: 'isInSituNursery',
    defaultValue: false
  },
  [COLUMN_NAME_IS_EX_SITU_NURSERY]: {
    label: 'Ex-Situ Nursery',
    IconType: CameraIndoor,
    iconStyle: {},
    searchParam: 'isExSituNursery',
    defaultValue: false
  },
  [COLUMN_NAME_IS_RESTORATION]: {
    label: 'Field Restoration',
    IconType: Construction,
    iconStyle: {},
    searchParam: 'isRestoration',
    defaultValue: false
  },
  [COLUMN_NAME_COMMUNITY_BASED]: {
    label: 'Community Based',
    IconType: People,
    iconStyle: {},
    searchParam: 'isCommunityBased',
    defaultValue: false
  },
  [COLUMN_NAME_IS_AQUARIUM]: {
    label: 'Aquarium',
    IconType: Water,
    iconStyle: {},
    searchParam: 'isAquarium',
    defaultValue: false
  },
  [COLUMN_NAME_IS_LABORATORY]: {
    label: 'Laboratory',
    IconType: Science,
    iconStyle: {},
    searchParam: 'isLaboratory',
    defaultValue: false
  },
  [COLUMN_NAME_BIODEGRADABLE_MATERIALS]: {
    label: 'Biodegradable Materials',
    IconType: EnergySavingsLeaf,
    iconStyle: { color: 'green' },
    searchParam: 'usesBiodegradableMaterials',
    defaultValue: false
  },
  [COLUMN_NAME_IS_MONITORING]: {
    label: 'Monitoring',
    IconType: MonitorHeart,
    iconStyle: {},
    searchParam: 'isMonitoring',
    defaultValue: false
  }
};
