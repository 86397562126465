import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const IconLabel = ({key, label, IconType, iconProps}) => {
  return (
    <Box sx={{display:'inline-flex'}}>
        <Box sx={{mr:1}}>
          <Typography key={key} variant="body1">{label}</Typography>
        </Box>
        {React.createElement(IconType, iconProps)}
    </Box>
  );
}

IconLabel.propTypes = {
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    IconType: PropTypes.elementType.isRequired,
    iconProps: PropTypes.object,
  };
IconLabel.defaultProps = {
  iconProps: {},
};
export default IconLabel;