export default {
    toolTitles: [
        "Select Project Goal",
        "Analyze Risks",
        "Select Methodology",
        "Find Funding and Collaborators",
        "Find Scientific or Expert Assistance",
        "Find Volunteers",
        "Apply for Permits",
    ]
};