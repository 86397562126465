import axios from 'axios';
import { BASE_URL_API } from '../constants';
export const getMethodologyPracticesByMethodology = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const methodologyPractices = await axios.post(
    `${BASE_URL_API}/methodologyPractice`,
    params,
    { headers }
  );
  return methodologyPractices;
};

export const getAllMethodologyPractice = async () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const methodologyPractices = await axios.get(
    `${BASE_URL_API}/methodologyPractice`,
    { headers }
  );
  return methodologyPractices;
};
