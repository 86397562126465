import React from 'react';
import { RecoilRoot } from 'recoil';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import './App.css';
import MapManager from './containers/mapManager';
import ProjectTools from './components/projectTools';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const App = ({showNewProjectButton, showInfoDialog, showProjectPlanner}) => {
  let showInfoDialogMapProp = showInfoDialog;
  let showNewProjectButtonMapProp = showNewProjectButton;
  let showAppBarMapProp = true;
  if (showProjectPlanner) {
    // If show project planner, map should not show info dialog or new button
    showInfoDialogMapProp = false;
    showNewProjectButtonMapProp = false;
    showAppBarMapProp = false;
  }

  return <RecoilRoot>
      <Stack>
        {showProjectPlanner && 
            <Box><Typography variant='h1' align='center'>Lets Plan a Project!</Typography></Box>
        } 
        <Stack direction='row'>
          <MapManager 
            showInfoDialog={showInfoDialogMapProp} 
            showNewProjectButton={showNewProjectButtonMapProp}
            showAppBar={showAppBarMapProp}
          />
          {showProjectPlanner && 
            <ProjectTools />
          }
        </Stack>
      </Stack>
    </RecoilRoot>;
}

App.propTypes = {
  showNewProjectButton: PropTypes.bool,
  showInfoDialog: PropTypes.bool,
  showProjectPlanner: PropTypes.bool,
};
App.defaultProps = {
  showNewProjectButton: false,
  showInfoDialog: true,
  showProjectPlanner: false,
};

export default App;
