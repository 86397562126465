import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import noop from 'lodash/noop';
import messages from './messages';

const IntroDialog = ({ open, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{alignSelf:"center"}}>{messages.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{messages.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>{messages.closeBtn}</Button>
      </DialogActions>
    </Dialog>
  );
};

IntroDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

IntroDialog.defaultProps = {
  handleClose: noop,
};
export default IntroDialog;
