export const LOCATION_TYPE_WORKSITE_NAME = 'worksiteNameLocation';
export const LOCATION_TYPE_NEAR_ME = 'nearMeLocation';
export const LOCATION_TYPE_COUNTRY = 'countryLocation';
export const LOCATION_TYPE_REGION = 'regionLocation';
export const LOCATION_TYPE_ALL = '';

export const WORKSITE_IDS_SEARCH_PARAM = 'worksiteIds';
export const WORKSITE_NAME_SEARCH_PARAM = 'worksiteName';
export const WORKSITE_COUNTRY_SEARCH_PARAM = 'worksiteCountry';
export const WORKSITE_REGION_SEARCH_PARAM = 'worksiteRegion';
export const WORKSITE_LOCATION_TYPE_SEARCH_PARAM = 'worksiteLocationType';

export const locationSearchTypes = {
  ['']: { label: 'All' },
  [WORKSITE_COUNTRY_SEARCH_PARAM]: { label: 'Country' },
  [WORKSITE_REGION_SEARCH_PARAM]: { label: 'Region' },
  [WORKSITE_NAME_SEARCH_PARAM]: { label: 'Site Name' },
};

export const locationTypeSearchParams = {
  [LOCATION_TYPE_WORKSITE_NAME]: [WORKSITE_IDS_SEARCH_PARAM, WORKSITE_NAME_SEARCH_PARAM],
  [LOCATION_TYPE_COUNTRY]: [WORKSITE_COUNTRY_SEARCH_PARAM],
  [LOCATION_TYPE_REGION]: [WORKSITE_REGION_SEARCH_PARAM],
  [LOCATION_TYPE_NEAR_ME]: []
};
