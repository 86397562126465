import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import VolunteerIcon from '@mui/icons-material/VolunteerActivism';
import DonateIcon from '@mui/icons-material/Savings';
import CloseIcon from '@mui/icons-material/Close';

import Pagination from '@mui/material/Pagination';
import OrgContact from '../orgContact';

import Grid from '@mui/material/Grid';
import { getMethodPracticeForUI } from './transform';
import ToolTipIcon from '../toolTipIcon';
import ToolTipIconButton from '../toolTipIconButton';
import OrgLogo from '../orgLogo';
import { Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';

import isEmpty from 'lodash/isEmpty';

const getMethodGrid = ({ methodName, methodTitle, description, methodType = '' }) => {
  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12}>
      <Grid item xs={12}>
        <Tooltip title={methodType}>
          <Typography align="center" variant="body1" sx={{ fontWeight: 'bold' }}>
            {methodTitle}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" xs={12} wrap="nowrap">
        <Grid item>
          <Typography align="center" variant="body1" sx={{ fontSize: 18 }}>
            {methodName}
          </Typography>
        </Grid>
        {!isEmpty(description) && (
          <Grid>
            <ToolTipIcon
              label={description}
              IconType={AddCircleIcon}
              iconProps={{ sx: { fontSize: 'small', pt: 0.5 } }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const MethodPracticeCard = ({ methodPractices, handleClose }) => {
  const [activeCard, setActiveCard] = useState(0);
  const methodPracticeUI = getMethodPracticeForUI(methodPractices[activeCard]);
  return (
    <Card
      variant="outlined"
      style={{ display: 'inline-block', position: 'relative', zIndex: 10 }}
      sx={{ minWidth: 400 }}
    >
      <CardContent>
        <Grid container>
          <Grid container item xs={11} wrap="nowrap" alignItems="center" justifyContent="center">
            <Grid container justifyContent="center" alignItems="center" wrap="nowrap" item xs={12} rowSpacing={1}>
              <Grid xs item container justifyContent="center" alignItems="center">
                {methodPracticeUI.logoPath && (
                  <OrgLogo
                    logoPath={methodPracticeUI.logoPath}
                    orgLink={methodPracticeUI.orgWebsite}
                  />
                )}
              </Grid>
              <Grid
                xs={8}
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item>
                  <a href={methodPracticeUI.orgWebsite} target="_blank" rel="noreferrer">
                    <Typography align="center" variant="h5">
                      {methodPracticeUI.organizationName}
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <OrgContact
                    phone={methodPracticeUI.organizationPhone}
                    email={methodPracticeUI.organizationEmail}
                    facebook={methodPracticeUI.organizationFacebook}
                    twitter={methodPracticeUI.organizationTwitter}
                    instagram={methodPracticeUI.organizationInstagram}
                    linkedIn={methodPracticeUI.organizationLinkedIn}
                  />
                </Grid>
              </Grid>
              {methodPracticeUI.orgsToolTipIconsInfo.length > 0 && (
                <Grid container item xs={1} alignItems="center" justifyContent="center">
                  {methodPracticeUI.orgsToolTipIconsInfo.map((t) => (
                    t.linkUrl ? 
                    <ToolTipIconButton
                      key={t.label}
                      label={t.label}
                      IconType={t.IconType}
                      iconProps={t.iconStyle ? { style: t.iconStyle } : {}}
                      onClickCallback={() =>
                        window.open(t.linkUrl, '_blank')
                      }
                    />
                    :
                    <ToolTipIcon
                      key={t.label}
                      label={t.label}
                      IconType={t.IconType}
                      iconProps={t.iconStyle ? { style: t.iconStyle } : {}}
                    />
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={1}
            sx={{ mt: -2 }}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Grid item>
              <IconButton aria-label="close" size="small" onClick={() => handleClose()}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container sx={{ mb: 1 }}>
            <Grid xs={12} item>
              <Typography align="center" variant="body1">
                {methodPracticeUI.workSiteName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ mb: 1 }}>
            <Grid xs={12} item>
              <Divider sx={{ borderBottomWidth: 3 }} variant="middle" />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {methodPracticeUI.restorationMethodologyData &&
              getMethodGrid({
                methodName: methodPracticeUI.restorationMethodologyData.name,
                methodTitle: 'Restoration Method',
                methodType: methodPracticeUI.restorationMethodologyData.type,
                description: methodPracticeUI.restorationMethodologyData.description
              })
            }
             {methodPracticeUI.restorationMethodologyGroupData &&
              getMethodGrid({
                methodName: methodPracticeUI.restorationMethodologyGroupData.name,
                methodTitle: 'Restoration Method',
                description: methodPracticeUI.restorationMethodologyGroupData.description
              })
            }
            {methodPracticeUI.scientificMethodologyData &&
              getMethodGrid({
                methodName: methodPracticeUI.scientificMethodologyData.name,
                methodType: methodPracticeUI.scientificMethodologyData.type,
                methodTitle: 'Science-Based Method',
                description: methodPracticeUI.scientificMethodologyData.description
              })
            }
            {methodPracticeUI.scientificMethodologyGroupData &&
              getMethodGrid({
                methodName: methodPracticeUI.scientificMethodologyGroupData.name,
                methodTitle: 'Science-Based Method',
                description: methodPracticeUI.scientificMethodologyGroupData.description
              })
            }
          </Grid>
          {methodPracticeUI.methodsToolTipIconsInfo.length > 0 && (
            <Grid container item xs={12} alignItems="center" justifyContent="center">
              {methodPracticeUI.methodsToolTipIconsInfo.map((t) => (
                <ToolTipIcon
                  key={t.label}
                  label={t.label}
                  IconType={t.IconType}
                  iconProps={t.iconStyle ? { style: t.iconStyle } : {}}
                />
              ))}
              {methodPracticeUI.lastYearActive && (
                <Tooltip title="Last Year Active">
                  <Typography>{methodPracticeUI.lastYearActive}</Typography>
                </Tooltip>
              )}
            </Grid>
          )}
          {methodPracticeUI.website && (
            <Grid item xs={12}>
              <Typography align="center" variant="body2" gutterBottom>
                <a href={methodPracticeUI.website} target="_blank" rel="noreferrer">
                  {methodPracticeUI.website}
                </a>
              </Typography>
            </Grid>
          )}
          {methodPractices.length > 1 && (
            <Grid container item xs={12} justifyContent="center">
              <Pagination
                page={activeCard + 1}
                onChange={(e, p) => setActiveCard(p - 1)}
                count={methodPractices.length}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

MethodPracticeCard.propTypes = {
  methodPractices: PropTypes.array,
  handleClose: PropTypes.func
};
export default MethodPracticeCard;
