import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from '@dylmye/mui-google-places-autocomplete';
import messages from './messages';
import { mapState } from '../map';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SearchLocationModal = ({open}) => {
  const [searchText, setSearchText] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openState, setOpenState] = useState(open);
  const setMapData = useSetRecoilState(mapState);
  const navigate = useNavigate();


  useEffect(() => {
    if (selectedOption != null) {
      const placeId = selectedOption?.place_id;
      if (placeId) {
        geocodeByPlaceId(placeId)
        .then(results => {
          setMapData((prevState) => ({
            ...prevState,
            initialBounds: results[0].geometry.viewport
            }));
            navigate('/projectPlanner');
        })
        .catch(error => console.error(error)).finally(() => setOpenState(false));
      }
    }
  }, [selectedOption])
  return (
    <div>
      <Modal
        open={openState}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {messages.header}         
         </Typography>
          <GooglePlacesAutocomplete
            inputValue={searchText}
            setInputValue={newValue => setSearchText(newValue)}
            value={selectedOption}
            setValue={(newOption) => setSelectedOption(newOption)}
            label={messages.googleSearchLabel}
            minLengthAutocomplete={3}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default SearchLocationModal;