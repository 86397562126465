export const METHOD_TYPE_RESTORATION = 'restorationMethod';
export const METHOD_TYPE_SCIENTIFIC = 'scientificMethod';

export const RESTORATION_TYPE_ASEXUAL_PROPAGATION = 'Asexual Propagation'; //1
export const RESTORATION_TYPE_SEXUAL_PROPAGATION = 'Sexual Propagation';
export const RESTORATION_TYPE_SUBSTRATUM_ENGANCEMENT = 'Substratum Enhancement';
export const RESTORATION_TYPE_CORAL_REEF_MANAGEMENT = 'Coral Reef Management';

export const restorationTypeIndex = {
    [RESTORATION_TYPE_ASEXUAL_PROPAGATION]: 1,
    [RESTORATION_TYPE_SEXUAL_PROPAGATION]: 2,
    [RESTORATION_TYPE_SUBSTRATUM_ENGANCEMENT]: 3,
    [RESTORATION_TYPE_CORAL_REEF_MANAGEMENT]: 4,
}
export const RESTORATION_METHOD_CATEGORIES_SEARCH_PARAM = 'restorationMethodCategories';
export const SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM = 'scientificMethodCategories';
//export const RESTORATION_METHOD_NAME_SEARCH_PARAM = 'restorationMethodName';
//export const SCIENTIFIC_METHOD_NAME_SEARCH_PARAM = 'scientificMethodName';

export const methodTypeSearchParams = {
  [METHOD_TYPE_RESTORATION]: [
    RESTORATION_METHOD_CATEGORIES_SEARCH_PARAM
  ],
  [METHOD_TYPE_SCIENTIFIC]: [
    SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM
  ]
};
