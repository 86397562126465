import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import VolunteerIcon from '@mui/icons-material/VolunteerActivism';
import DonateIcon from '@mui/icons-material/Savings';

const COLUMN_NAME_ACCEPTS_VOLUNTEERS = 'accepts_volunteers_internships';
const COLUMN_NAME_GIVES_PERMITS = 'gives_permits';
const COLUMN_NAME_PROVIDES_FUNDING = 'provides_funding';
const COLUMN_NAME_PROVIDES_WORKSHOPS = 'provides_workshops';
const COLUMN_NAME_ACCEPTS_DONATIONS = 'accepts_donations';
// const COLUMN_NAME_HAS_CAP = 'has_cap';

export const FLAG_DETAILS = {
  [COLUMN_NAME_ACCEPTS_VOLUNTEERS]: {
    label: 'Accepts Volunteers',
    IconType: VolunteerIcon,
    iconStyle: { color: 'green' },
    searchParam: 'orgAcceptsVolunteers',
    linkOrgColumn: 'volunteer_website',
    defaultValue: false
  },
  [COLUMN_NAME_ACCEPTS_DONATIONS]: {
    label: 'Accepts Donations',
    IconType: DonateIcon,
    iconStyle: { color: 'green' },
    searchParam: 'orgAcceptsDonations',
    linkOrgColumn: 'donations_website',
    defaultValue: false
  },
  [COLUMN_NAME_GIVES_PERMITS]: {
    label: 'Provides Permits',
    IconType: WorkspacePremiumIcon,
    iconStyle: {},
    searchParam: 'orgGivesPermits',
    defaultValue: false
  },
  [COLUMN_NAME_PROVIDES_FUNDING]: {
    label: 'Provides Funding',
    IconType: AttachMoneyIcon,
    iconStyle: { color: 'green' },
    searchParam: 'orgProvidesFunding',
    defaultValue: false,
    linkOrgColumn: 'funding_website',
  },
  [COLUMN_NAME_PROVIDES_WORKSHOPS]: {
    label: 'Provides Workshops',
    IconType: HomeRepairServiceIcon,
    iconStyle: { color: 'green' },
    searchParam: 'orgProvidesWorkshops',
    defaultValue: false,
    linkOrgColumn: 'workshops_website',
  }
  /*
    [COLUMN_NAME_HAS_CAP]: {
        label: "Has CAP",
        IconType: GroupsIcon,
        iconStyle: {},
        searchParam: 'orgHasCap',
        defaultValue: false,
    },*/
};
