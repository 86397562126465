import axios from 'axios';
import { BASE_URL_API } from '../constants';
import isEmpty from 'lodash/isEmpty';

export const getOrganizations = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const orgs = await axios.post(
    `${BASE_URL_API}/organization`,
    params,
    { headers }
  );
  return orgs.data;
};

export const getOrganizationIdsFromMethodPractices = (methodPractices) => {
  if (isEmpty(methodPractices)) {
    return [];
  }
  const uniqueOrgIds = [...new Set(methodPractices.map(mp => mp.organizationId?.id))];
  return uniqueOrgIds;
}