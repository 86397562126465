import React from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { snackbarState } from '../snackbar';
import messages from './messages';

const ToolTipIconButtonCopyClipboard = ({ label, IconType, iconProps, onClickCallback, color, buttonStyles }) => {
  const setSnackbarProps = useSetRecoilState(snackbarState);
  const handleIconClick = () => {
    navigator.clipboard.writeText(label);
    setSnackbarProps((prevState) => ({
      ...prevState,
      open: true,
      message: messages.copyClipboard,
      alertSeverity: 'success',
    }));
    onClickCallback();
  }
  return (
    <Tooltip title={label}>
      <IconButton style={{ color, ...buttonStyles }} onClick={() => handleIconClick()}>
        {React.createElement(IconType, iconProps)}
      </IconButton>
    </Tooltip>
  );
};

ToolTipIconButtonCopyClipboard.propTypes = {
  label: PropTypes.string.isRequired,
  IconType: PropTypes.elementType.isRequired,
  iconProps: PropTypes.object,
  onClickCallback: PropTypes.func,
  color: PropTypes.string,
  buttonStyles: PropTypes.object,
};
ToolTipIconButtonCopyClipboard.defaultProps = {
  iconProps: {},
  onClickCallback: () => {},
  color: 'blue',
  buttonStyles: {},
};

export default ToolTipIconButtonCopyClipboard;
