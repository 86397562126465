import axios from 'axios';
import { BASE_URL_API } from '../constants';

export const getScientificMethodologies = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const sm = await axios.get(
    `${BASE_URL_API}/scientificMethodology`,
    params,
    { headers }
  );
  return sm.data;
};
