import axios from 'axios';
import { BASE_URL_API } from '../constants';

export const getRestorationMethodologies = async (params) => {
  // axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  };
  const rm = await axios.get(
    `${BASE_URL_API}/restorationMethodology`,
    params,
    { headers }
  );
  return rm.data;
};
