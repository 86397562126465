import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from '@mui/system';
import IconLabel from '../iconLabel';

import { FLAG_DETAILS as METHOD_FLAG_DETAILS } from '../../constants/methodPracticeFlags';
import {
  RESTORATION_METHOD_CATEGORIES_SEARCH_PARAM,
  SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM,
  METHOD_TYPE_RESTORATION,
  METHOD_TYPE_SCIENTIFIC,
  RESTORATION_TYPE_ASEXUAL_PROPAGATION,
  RESTORATION_TYPE_SEXUAL_PROPAGATION,
  RESTORATION_TYPE_SUBSTRATUM_ENGANCEMENT,
  RESTORATION_TYPE_CORAL_REEF_MANAGEMENT,
  methodTypeSearchParams,
  restorationTypeIndex,
  
} from '../../constants/methodologies';

import has from 'lodash/has';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled(Button)(() => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  backgroundColor: 'white',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const sortRestorationMethods = (a, b) => {
  // Sort based on restorationTypeIndex
  const typeAIndex = restorationTypeIndex[a.type];
  const typeBIndex = restorationTypeIndex[b.type];
  if (typeAIndex < typeBIndex) {
    return -1;
  }
  if (typeAIndex > typeBIndex) {
    return 1;
  }

  // names must be equal
  return 0;
};

const sortScientificMethods = (a, b) => {
  // Sort alphabetically
  const typeA = a.type.toUpperCase(); // ignore upper and lowercase
  const typeB = b.type.toUpperCase(); // ignore upper and lowercase
  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const filterUnique = (arr, keyProps) => {
  const kvArray = arr.map(entry => {
   const key = keyProps.map(k => entry[k]).join('|');
   return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
 };

const isOptionEqualToValue = (option, value) => {
  return (option.subtype == value.subtype && option.type == value.type);
};

const renderGroup = (groupParams, setSelectedGroupOptions, selectedGroupOptions, groupIndexes, handleGroupChecked) => {
  const allOptionsSelected = selectedGroupOptions[groupParams.group]?.options?.length == groupParams.children.length;
  const groupOpen = selectedGroupOptions[groupParams.group]?.forceOpen || 
  (selectedGroupOptions[groupParams.group]?.options?.length > 0 && !allOptionsSelected)
  return (
    <li>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 2 }}
        // checked if all children are selected
        checked={allOptionsSelected}
        value={groupParams.group}
        onChange={(e) => {
          const isChecked = e.target.checked;
          let allOptionsData = [];
          if (isChecked) {
            // Select all options
            allOptionsData = groupParams.children.map(
              (child) => ({ type: child.props['data-type'], subtype: child.props['data-subtype']})
            );
          }
          handleGroupChecked(allOptionsData);
        }}
      />
      <GroupHeader onClick={
                  () => setSelectedGroupOptions(
                    { 
                      ...selectedGroupOptions, 
                      [groupParams.group]: 
                        has(selectedGroupOptions, [groupParams.group])
                           ? {...selectedGroupOptions[groupParams.group], forceOpen: !selectedGroupOptions[groupParams.group].forceOpen}
                           : { forceOpen: true }
                    })
                  }>
        {`${groupIndexes[groupParams.group]}. ${groupParams.group}`}
      </GroupHeader>
      { groupOpen && <GroupItems>{groupParams.children}</GroupItems> }
    </li>
  );
}

const MethodSearchFilters = ({
  handleUpdateMethodSearchParams,
  filterData,
  initMethodSearchParams
}) => {
  const {
    restorationMethodCategories = [],
    scientificMethodCategories = [],
    ...methodSearchParams
  } = initMethodSearchParams;
  const autocompleteOnChange = (value, searchParam) => {
    handleUpdateMethodSearchParams({
      [searchParam]: value.map(
        (data) => ({ type: data.type, subtype: data.subtype})
     )});
  }
  
  const RestorationMethodFormControl = ({ enabled }) => {
    const options = filterUnique(filterData.allRestorationMethods, ['type', 'subtype']).map((method) => {
      return ({ type: method.type, subtype: method.subtype, name: method.name })
    });

    options.sort(sortRestorationMethods);
    // Look at options and create index for each type
    const typeIndexes = options.reduce(
      (indexes, opt) => has(indexes, opt.type) ? indexes : { ...indexes, [opt.type]: restorationTypeIndex[opt.type] },{});
    const initSelectedGroupOptions = restorationMethodCategories.reduce(
      (groups, category) => {
        const options = has(groups, [category.type]) ? groups[category.type].options : [];
        options.push(category.subtype);
        return ({...groups, [category.type]: { options, forceOpen: false } });
      },
      {}
    );
    const [selectedGroupOptions, setSelectedGroupOptions] = useState(initSelectedGroupOptions);
    const handleGroupChecked = (allOptionsData) => {
      handleUpdateMethodSearchParams({
        [RESTORATION_METHOD_CATEGORIES_SEARCH_PARAM]: restorationMethodCategories.concat(allOptionsData)
      });
    };
    return (
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={!enabled}
          limitTags={3}
          multiple
          id="restorationMethod-select-id"
          options={options}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.subtype}
          renderOption={(props, option, { selected }) => {
            return (
              <li key={option.subtype} data-type={option.type} data-subtype={option.subtype} {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  value={option.subtype}
                />
                {option.subtype}
              </li>);
            }
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="Restoration Methodologies" />
          )}
          renderGroup={(params) => renderGroup(params, setSelectedGroupOptions, selectedGroupOptions, typeIndexes, handleGroupChecked)}
          onChange={(e, value) => autocompleteOnChange(value, RESTORATION_METHOD_CATEGORIES_SEARCH_PARAM)}
          defaultValue={ restorationMethodCategories }
        />
    );
  };
  RestorationMethodFormControl.propTypes = {
    enabled: PropTypes.bool
  };
  RestorationMethodFormControl.defaultProps = {
    enabled: true
  };

  const ScientificMethodFormControl = ({ enabled }) => {
    const options = filterUnique(filterData.allScientificMethods, ['type', 'subtype']).map((method) => {
      return ({ type: method.type, subtype: method.subtype })
    });
    options.sort(sortScientificMethods);
    // Look at options and create index for each type
    let indexCounter = 0;
    const typeIndexes = options.reduce(
      (indexes, opt) => has(indexes, opt.type) ? indexes : { ...indexes, [opt.type]:++indexCounter },{});
    const initGroupsOpen = scientificMethodCategories.reduce(
      (groups, category) => ({...groups, [category.type]: true }),
      {}
    );
    const [groupsOpen, setGroupsOpen] = useState(initGroupsOpen);  
    return (       
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={!enabled}
          limitTags={3}
          multiple
          id="scientificMethod-select-id"
          options={options}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.subtype}
          renderOption={(props, option, { selected }) => {
            return (
              <li key={option.subtype} {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  value={option.subtype}
                />
                {option.subtype}
              </li>);
            }
          }
          renderInput={(params) => (
            <TextField {...params} placeholder="Science-Based Methodologies" />
          )}
          renderGroup={(params) => renderGroup(params, setGroupsOpen, groupsOpen, typeIndexes)}
          onChange={(e, value) => autocompleteOnChange(value, SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM)}
          defaultValue={ scientificMethodCategories  }
        />
    );
  };
  ScientificMethodFormControl.propTypes = {
    enabled: PropTypes.bool
  };
  ScientificMethodFormControl.defaultProps = {
    enabled: true
  };

  /*const RestorationMethodNameFormControl = ({ enabled }) => {
    return (
      <FormControl fullWidth disabled={!enabled}>
        <InputLabel shrink id="restorationMethodSubType-label-id">
          Name
        </InputLabel>
        <Select
          displayEmpty
          labelId="restorationMethodSubType-label"
          id="restorationMethodSubType-select"
          value={restorationMethodSubType}
          label="Name"
          onChange={(e) => {
            handleUpdateMethodSearchParams({
              [RESTORATION_METHOD_SUBTYPE_SEARCH_PARAM]: e.target.value
            });
          }}
        >
          {Object.keys(filterData.allRestorationMethodsByTechnique[restorationMethodType]).map(
            (restorationDataIndex) => {
              const restorationData =
                filterData.allRestorationMethodsByTechnique[restorationMethodType][
                  restorationDataIndex
                ];
              return (
                <MenuItem
                  key={restorationDataIndex}
                  value={restorationData.name == ALL_OPTION ? '' : restorationData.name}
                >
                  {restorationData.name}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    );
  };
  RestorationMethodNameFormControl.propTypes = {
    enabled: PropTypes.bool
  };
  RestorationMethodNameFormControl.defaultProps = {
    enabled: true
  };
  const ScientificMethodNameFormControl = ({ enabled }) => {
    return (
      <FormControl fullWidth disabled={!enabled}>
        <InputLabel shrink id="scientificMethodSubType-label-id">
          Name
        </InputLabel>
        <Select
          displayEmpty
          labelId="scientificMethodSubType-label"
          id="scientificMethodSubType-select"
          value={scientificMethodSubType}
          label="Name"
          onChange={(e) => {
            handleUpdateMethodSearchParams({
              [SCIENTIFIC_METHOD_SUBTYPE_SEARCH_PARAM]: e.target.value
            });
          }}
        >
          {Object.keys(filterData.allScientificMethodsByTechnique[scientificMethodType]).map(
            (scientificDataIndex) => {
              const scientificData =
                filterData.allScientificMethodsByTechnique[scientificMethodType][
                  scientificDataIndex
                ];
              return (
                <MenuItem
                  key={scientificDataIndex}
                  value={scientificData.name == ALL_OPTION ? '' : scientificData.name}
                >
                  {scientificData.name}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    );
  };
  ScientificMethodNameFormControl.propTypes = {
    enabled: PropTypes.bool
  };
  ScientificMethodNameFormControl.defaultProps = {
    enabled: true
  };
  */

  const methodSearchParamsHandleOnChangeCheckbox = (event) => {
    const currentValue = event.target.value;
    const isChecked = event.target.checked;
    const newSearchParams = { ...methodSearchParams };
    newSearchParams[currentValue] = isChecked;
    if (!isChecked && has(methodTypeSearchParams, currentValue)) {
      // We unchecked a method type (i.e. restoration or scientific)
      // Lets remove the related searchParams from the search
      methodTypeSearchParams[currentValue].forEach((searchParam) => {
        newSearchParams[searchParam] = [];
      });
    }
    handleUpdateMethodSearchParams(newSearchParams);
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Methodologies</Typography>
      </Grid>
      <Grid item xs={12}>        
        <RestorationMethodFormControl />
      </Grid>
      <Grid item xs={12}>       
        <ScientificMethodFormControl />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          {Object.keys(METHOD_FLAG_DETAILS).map((column) => (
            <FormControlLabel
              label={
                <IconLabel 
                  key={column} 
                  label={METHOD_FLAG_DETAILS[column].label} 
                  IconType={METHOD_FLAG_DETAILS[column].IconType}
                  iconProps={METHOD_FLAG_DETAILS[column].iconStyle ? { style: METHOD_FLAG_DETAILS[column].iconStyle } : {}}
                />}
              key={column}
              control={
                <Checkbox
                  value={METHOD_FLAG_DETAILS[column].searchParam}
                  onChange={methodSearchParamsHandleOnChangeCheckbox}
                  checked={!!methodSearchParams[METHOD_FLAG_DETAILS[column].searchParam]}
                />
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};
MethodSearchFilters.propTypes = {
  handleUpdateMethodSearchParams: PropTypes.func.isRequired,
  filterData: PropTypes.object.isRequired,
  initMethodSearchParams: PropTypes.object
};
MethodSearchFilters.defaultProps = {
  initMethodSearchParams: {}
};
export default MethodSearchFilters;
