import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import Marker from './Marker';

const ToolTipMarker = ({toolTipText, ...markerProps}) => {
  return <Tooltip title={toolTipText}>
            <Marker {...markerProps} />
        </Tooltip>
};
ToolTipMarker.propTypes = {
    toolTipText: PropTypes.string.isRequired,
};
export default ToolTipMarker;