import React from 'react';
import PropTypes from 'prop-types';
import defaultMarkerSrc from './markersmall2.png';

const logoStyle = {
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translate(-50%, -100%)',
  zIndex: 1
};

const LogoMarker = ({ logoPath, fallbackToDefault }) => {
  if (logoPath && logoPath.length > 0) {
    return <img style={logoStyle} src={`${process.env.PUBLIC_URL}/logos/${logoPath}`} alt="pin" />;
  } else if (fallbackToDefault) {
    return <img style={logoStyle} src={defaultMarkerSrc} alt="pin" />;
  }
  return '';
};

LogoMarker.propTypes = {
  logoPath: PropTypes.string,
  fallbackToDefault: PropTypes.bool
};
LogoMarker.defaultProps = {
  fallbackToDefault: true
};
export default LogoMarker;
